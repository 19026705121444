<template>

<app-content :loading="is.loading">

	<app-content-head title="Badge transfer" subtitle="You can choose to transfer your badge to another person." back="Convention.Registration.Badge" />

	<app-content-body v-if="!is.loading" :is-call="true">

		<app-content-call v-if="is.disabled" icon="crossOnly" title="Transfer badge" text="Sorry, transfers are not permitted." :danger="true" />
		<app-content-call v-if="is.early" icon="crossCalendar" title="Transfer badge" text="Sorry, the transfer window has not opened yet." :danger="true" />
		<app-content-call v-if="is.late" icon="crossCalendar" title="Transfer badge" text="Sorry, the transfer window has now closed." :danger="true" />

		<app-content-call v-if="is.allowed" icon="transfer" :loading="is.confirming" title="Transfer badge" text="Enter the name and e-mail address of the person you wish to transfer your badge to." :disabled="$v.$invalid || is.checking" :danger="true" button="Confirm" v-on:click="onConfirmClick">

			<div class="transfer-info" v-html="$convention.settings.badges.transfer.text.before" />

			<div class="transfer-addons" v-if="addons">
				
				<p><b>Please note: Your badge has {{ addons }} refundable add-on<template v-if="addons > 1">s</template>. If you do not wish to transfer <template v-if="addons > 1">these</template><template v-if="addons === 1">this</template> as well, you must refund before initiating the transfer.</b></p>
			
				<app-button text="Go to Purchases" v-on:click="onPurchasesClick" />
			
			</div>

			<app-input-account class="transfer-input" :original="''" endpoint="convention/registration/transfer/exists" :reverse="true" type="badge" :text="emailMessages" v-model="model.email" :validation="$v.model.email" v-on:checking="is.checking = $event" v-on:exists="onExistsChange" />
			<app-input-text class="transfer-input" v-model="model.firstname" :validation="$v.model.firstname" placeholder="Enter firstname" />
			<app-input-text class="transfer-input" v-model="model.lastname" :validation="$v.model.lastname" placeholder="Enter lastname" />

		</app-content-call>
	
	</app-content-body>

</app-content>

</template>

<script>

import { required, email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate';

export default {

	mixins: [validationMixin],

	data: function() {

		return {
			is: {
				confirming: false,
				allowed: false,
				loading: true,
				checking: false,
				disabled: false,
				early: false,
				late: false
			},
			model: {
				firstname: '',
				lastname: '',
				email: ''
			},
			emailMessages: {
				info: 'Please note the e-mail address cannot already be associated with an existing badge.',
				exists: 'This e-mail address is already associated with another badge.',
				available: 'This e-mail is available to transfer to.'
			},
			exists: false,
			addons: false
		}

	},

	validations: {
		model: {
			firstname: {
				required
			},
			lastname: {
				required
			},
			email: {
				required,
				email,
				notExist: function() {

					return !this.exists

				}
			}
		}
	},

	created: async function() {

		await this.$api.get('convention/registration/transfer').then(function(json) {

			this.is.allowed = true
			this.addons = json.addons

		}.bind(this), function(json) {

			this.is.early = json.reason === 'early'
			this.is.late = json.reason === 'late'
			this.is.disabled = json.reason === 'disabled' || !json.reason

		}.bind(this))

		this.is.loading = false

	},

	methods: {
		
		onPurchasesClick: function() {

			this.$router.push({
				name: 'Convention.Registration.Purchases'
			})

		},

		onExistsChange: function(e) {

			this.exists = e.exists

		},

		onConfirmClick: async function() {

			this.is.confirming = true

			await this.$api.post('convention/registration/transfer', this.model).then(function() {

				this.$router.push({
					name: 'Purchases'
				})

			}.bind(this), function() {

				this.$notify({
					message: ['Sorry, your badge could not be transferred. Please contact support.'],
					type: 'error'
				})

			}.bind(this))

			this.is.confirming = false

		}

	}

}

</script>

<style scoped>

.transfer-addons,
.transfer-info {
	text-align: center;
	padding: 20px;
	border: 1px solid #c55b5b;
	background-color: #c55b5b;
	color: #fff;
	font-weight: 400;
	line-height: 18px;
	font-size: 14px;
	border-radius: 10px;
	margin: 20px 0px;
}

.transfer-addons {
	border: 1px solid #ffbe7a;
	background-color: #ffbe7a;
	margin-top: 0px;
}

.transfer-addons .button {
	width: 240px;
	margin-top: 20px;
}

.transfer-input {
	width: 100%;
	margin-bottom: 0px;
}

</style>