import { render, staticRenderFns } from "./ConventionRegistrationTransfer.vue?vue&type=template&id=bec7c728&scoped=true&"
import script from "./ConventionRegistrationTransfer.vue?vue&type=script&lang=js&"
export * from "./ConventionRegistrationTransfer.vue?vue&type=script&lang=js&"
import style0 from "./ConventionRegistrationTransfer.vue?vue&type=style&index=0&id=bec7c728&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bec7c728",
  null
  
)

export default component.exports